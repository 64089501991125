import Vue from 'vue'
import axios from 'axios';
import logger from "@/plugins/logger";
import mock from "@/plugins/mock";
import store from "@/store";
import {
  AUTH_SESSION_STORAGE,
  AUTH_TOKEN_NAME
} from "@/constants";
import router from '@/router/index';

const service = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? '' : process.env.VUE_APP_API_URL, // url = base url + request url
  timeout: 30000,
});
Vue.prototype.$axios = service


const getRequestKey = (config) => `${config.method}:${config.url}`

const pendingRequests = new Set()
const requestControllers = new Map()
const loadingTimers = new Map()

// Request interceptors
service.interceptors.request.use(
  (config) => {

    const requestKey = getRequestKey(config)
    if (pendingRequests.has(requestKey)) {
      const controller = new AbortController()
      config.signal = controller.signal
      controller.abort("Duplicate request")
      requestControllers.delete(requestKey)
      return Promise.reject(new Error("Duplicate request"))
    }
    const timer = setTimeout(() => {
      store.dispatch('showLoading').then();
      loadingTimers.delete(requestKey)
    }, 300);

    loadingTimers.set(requestKey, timer)

    pendingRequests.add(requestKey)

    // Add X-Access-Token header to every request, you can add other custom headers here
    let token = store.getters.getSessionToken;

    if (!token) {
      const content = localStorage.getItem(AUTH_SESSION_STORAGE);

      if (content) {
        token = content;

        logger.debug("login page token:" + token);

        if (token) {
          store.dispatch("setSessionToken", content).then();
        }
      }
    }

    logger.debug("user-token", token);

    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
      config.headers[AUTH_TOKEN_NAME] = token;
    }

    logger.debug("request-config", config);

    return config;
  },
  (error) => {
    logger.debug("onRejected", error);
    return Promise.reject(error);
  },
);

service.interceptors.response.use((response) => {
  const requestKey = getRequestKey(response.config)
  store.dispatch('hideLoading').then()
  requestControllers.delete(requestKey)
  pendingRequests.delete(requestKey)

  logger.debug('response', response);

  return response;
}, (error) => {
  logger.debug('error', error.response)

  const requestKey = getRequestKey(error.config || {})
  store.dispatch('hideLoading').then();
  requestControllers.delete(requestKey)
  pendingRequests.delete(requestKey)

  if (!axios.isCancel(error)) {
    return handleErrorResponse(error)
  } else {
    console.warn("요청 취소됨:", error.message)
  }

  //console.debug('logOut', error)
  // let errorMsg = '';
  // let token = store.getters.getSessionToken;



  //에러 alert 추가
  // store.dispatch('addAlert', {
  //   'type': 'error',
  //   'message': errorMsg,
  // }).then();

  return Promise.reject(error);
})


async function handleErrorResponse(error) {
  console.log(error)
  if (error.response) {
    switch (error.response.status) {
      case 401:
        console.error("인증 실패")
        break;
      case 403:
        if (router.currentRoute.name !== "Login") {
          router.replace({
            name: 'Login'
          })
          alert('요청 하신 서비스에 대한 권한이 없습니다.')
          store.commit('sessionToken', null);
        }
        break;
      case 500:
        console.error("서버 오류가 발생했습니다.")
        break;
      default:
        console.error(`응답 오류: ${error.response.status}`)
    }
  } else if (error.request) {
    console.error("서버 응답 없음:", error.message)
  } else {
    console.error("요청 설정 오류:", error.message)
  }
  return Promise.reject(error)
}


export default async ($uri, $method, $payload, $header) => {
  if (process.env.VUE_APP_DATA_MODE === 'mock') {
    const mockValue = mock.getMock($uri);

    if (mockValue) {
      return mockValue;
    }
  }

  logger.debug(`[${$uri}:${$method}] proxy with : ${JSON.stringify($payload)}`);

  const {
    data
  } = await service({
    url: $uri,
    method: $method,
    data: $payload,
    headers: $header
  });

  logger.debug(data);

  return data;
};
