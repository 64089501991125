import {accountAsAdmin, loginAsAdmin} from '@/api/login';
import {AUTH_ADMIN_STORAGE, AUTH_TOKEN_NAME} from '@/constants';
import logger from "@/plugins/logger";

const adminModule = {
  state: {
    failCount: 0,
    loginId: "",
    userName: "",
  },

  getters: {
    adminName: (state) => state.userName,
    adminFailCount: (state) => state.failCount,
  },

  mutations: {
    SET_ADMIN_INFO(state, payload) {
      state.failCount = 0;
      state.userName = payload.userName;

      localStorage.setItem(AUTH_TOKEN_NAME, payload.token);

      logger.debug("save storage", payload);

      localStorage.setItem(AUTH_ADMIN_STORAGE, JSON.stringify(payload));
    },
    CLEAR_ADMIN_INFO(state) {
      state.failCount = 0;
      state.loginId = "";
      state.userName = "";
      state.token = "";

      localStorage.removeItem(AUTH_TOKEN_NAME);
      localStorage.removeItem(AUTH_ADMIN_STORAGE);
    },
    addAdminFailCount(state) {
      state.failCount += state.failCount;
    },
  },
  actions: {
    async doAdminReLogin(context, payload) {
      try {
        logger.debug("admin user login from local storage");

        await context.commit('SET_ADMIN_INFO', payload);

        // navigate user main
        await context.dispatch('goMainPage', {pageName: "MemberDashboard"});
      } catch (err) {
        logger.warn('[vuex.user] doAdminReLogin', err);
        return Promise.reject(err);
      }
    },
    // Login user
    doAdminLogin: async (context, payload) => {
      try {
        const response = await loginAsAdmin(payload.id, payload.password);
        // validate login
        if (!response['token']) {
          logger.debug("token null login fail");
          await context.commit('addAdminFailCount');
          return response;
        }

        logger.debug("token received");
        await context.dispatch('setSessionToken', {token: response['token']});

        const accountInfo = await accountAsAdmin();

        await context.commit('SET_ADMIN_INFO', accountInfo);

        // navigate user main
        await context.dispatch('goMainPage', {pageName: "MemberDashboard"});
      } catch (err) {
        logger.warn('[vuex.user] loginAsAdmin', err);
        return Promise.reject(err);
      }
    },
    doAdminLogOut: async (context) => {
      try {
        logger.debug('[vuex.admin] LogOut');
        await context.dispatch('setSessionToken', {token: null});

        await context.commit('CLEAR_ADMIN_INFO', {});
        await context.dispatch('goMainPage', {
          pageName: "Login"
        });
      } catch (err) {
        logger.warn('[vuex.user] LogOut', err);
      }
    },
  },
};

export default adminModule;
