import {mapActions, mapGetters} from "vuex";

export default {

  methods: {
    ...mapActions(["addAlert", "doAdminLogin", "doAdminLogOut", "goMainPage"]),
    ...mapGetters(["adminName"]),
    nav(target, subPage) {
      this.goMainPage({pageName: target, subPage: subPage}).then();
    }
  }
}
