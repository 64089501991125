export default {
  "/api/send-list": {
    success: true,
    record: [
      { id: 1, requestTime: "2020-10-10 11:11:11", subject: "dummy1", text: "dummy-text1", sendType: "type", status: "status", sendCount: 10 },
      { id: 2, requestTime: "2020-10-10 11:11:12", subject: "dummy2", text: "dummy-text2", sendType: "type", status: "status", sendCount: 20 },
      { id: 3, requestTime: "2020-10-10 11:11:13", subject: "dummy3", text: "dummy-text3", sendType: "type", status: "status", sendCount: 30 },
    ],
    next: false,
    nextStart: -1
  }
};
