export default {
  log(text, ...params) {
    console.log(text, params)
  },
  warn(text, ...params) {
    console.warn(text, params)
  },
  error(text, ...params) {
    console.error(text, params)
  },
  debug(text, ...params) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(text, params)
    }
  }
}
