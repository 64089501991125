<template>
  <!-- sidebar -->
  <transition>
    <div class="nk-sidebar" name="slide-fade" v-if="toggleNavStatus()">
      <div class="nk-nav-scroll">
        <ul class="metismenu" id="menu">
          <li class="nav-label">Main <span class="mobile_exit ion-close" @click="toggleNav"></span></li>
          <li :class='{ active: name == "MemberDashboard" }'>
            <router-link :to="{ name: 'MemberDashboard' }"><i class="mdi mdi-view-dashboard"></i> <span
                class="nav-text">Dashboard</span></router-link>
          </li>
          <li class="nav-label">정보조회</li>
          <li :class='{ active: name == "InformationUserInfo" }'>
            <router-link :to="{ name: 'InformationUserInfo' }"><i class="mdi mdi-account-search"></i><span
                class="nav-text">회원 정보</span></router-link>
          </li>
          <li :class='{ active: name == "InformationPartnerInfo" }'>
            <router-link :to="{ name: 'InformationPartnerInfo' }"><i class="mdi mdi-account-box"></i><span
                class="nav-text">파트너 정보</span></router-link>
          </li>
          <li :class='{ active: name == "InformationChargeStatus" }'>
            <router-link :to="{ name: 'InformationChargeStatus' }"><i class="mdi mdi-credit-card-plus"></i><span
                class="nav-text">충전 현황</span></router-link>
          </li>
          <li :class='{ active: name == "InformationSend" }'>
            <router-link :to="{ name: 'InformationSend' }"><i class="mdi mdi-send"></i><span class="nav-text">발송
                현황</span></router-link>
          </li>
          <li :class='{ active: name == "InformationReportPartner" }'>
            <router-link :to="{ name: 'InformationReportPartner' }"><i class="mdi mdi-chart-line"></i><span
                class="nav-text">파트너 수익 내역</span></router-link>
          </li>
          <li :class='{ active: name == "InformationSales" }'>
            <router-link :to="{ name: 'InformationSales' }"><i class="mdi mdi-credit-card"></i><span class="nav-text">매출
                분석</span></router-link>
          </li>


          <li :class='{ active: name == "InformationChargeHistory" }'>
            <router-link :to="{ name: 'InformationChargeHistory' }"><i class="mdi mdi-cash-multiple"></i><span
                class="nav-text">충전금 내역</span></router-link>
          </li>

          <li :class='{ active: name == "InformationPointHistory" }'>
            <router-link :to="{ name: 'InformationPointHistory' }"><i class="mdi mdi-parking"></i><span
                class="nav-text">포인트 내역</span></router-link>
          </li>


          <li class="nav-label">신청/접수내역</li>
          <li :class='{ active: name == "RequestSendCheck" }'>
            <router-link :to="{ name: 'RequestSendCheck' }"><i class="mdi mdi-arrange-send-backward"></i><span
                class="nav-text">발송 검수<span class="text-danger" v-if="newSendAudit"> *</span></span></router-link>
          </li>
          <li :class='{ active: name == "RequestTransform" }'>
            <router-link :to="{ name: 'RequestTransform' }"><i class="mdi mdi-swap-horizontal"></i><span
                class="nav-text">기업 회원 전환<span class="text-danger" v-if="newApplyCorp"> *</span></span></router-link>
          </li>
          <li :class='{ active: name == "RequestPartnerTransform" }'>
            <router-link :to="{ name: 'RequestPartnerTransform' }"><i class="mdi mdi-sync-alert"></i><span
                class="nav-text">기업 파트너 심사<span class="text-danger" v-if="newBusinessPartnerAudit"> *</span></span>
            </router-link>
          </li>
          <li :class='{ active: name == "RequestSendNumber" }'>
            <router-link :to="{ name: 'RequestSendNumber' }"><i class="mdi mdi-cellphone-dock"></i><span
                class="nav-text">발신번호 등록<span class="text-danger" v-if="newCallerAudit"> *</span></span></router-link>
          </li>
          <li :class='{ active: name == "RequestRefund" }'>
            <router-link :to="{ name: 'RequestRefund' }"><i class="mdi mdi-credit-card-off"></i><span
                class="nav-text">환불
                신청<span class="text-danger" v-if="newRefund"> *</span></span></router-link>
          </li>
          <li :class='{ active: name == "RequestPartnerPayment" }'>
            <router-link :to="{ name: 'RequestPartnerPayment' }"><i class="mdi mdi-credit-card"></i><span
                class="nav-text">파트너 정산</span></router-link>
          </li>
          <li :class='{ active: name == "RequestQna" }'>
            <router-link :to="{ name: 'RequestQna' }"><i class="mdi mdi-comment-question-outline"></i><span
                class="nav-text">1:1 문의<span class="text-danger" v-if="newInquiry"> *</span></span></router-link>
          </li>

          <li :class='{ active: name == "RequestMainNumber" }'>
            <router-link :to="{ name: 'RequestMainNumber' }"><i class="mdi mdi-cellphone"></i><span
                class="nav-text">대표번호
                가입신청<span class="text-danger" v-if="newNumber"> *</span></span></router-link>
          </li>

          <li class="nav-label">서비스 운영/관리</li>
          <li :class='{ active: name == "OperationNotice" }'>
            <router-link :to="{ name: 'OperationNotice' }"><i class="mdi mdi-bullhorn"></i><span
                class="nav-text">공지사항</span></router-link>
          </li>
          <li :class='{ active: name == "OperationHelp" }'>
            <router-link :to="{ name: 'OperationHelp' }"><i class="mdi mdi-help-circle"></i><span class="nav-text">자주 묻는
                질문</span></router-link>
          </li>
          <li :class='{ active: name == "OperationPopupUpdate" }'>
            <router-link :to="{ name: 'OperationPopupUpdate' }"><i class="mdi mdi-page-layout-body"></i><span
                class="nav-text">팝업 관리
              </span></router-link>
          </li>
          <li :class='{ active: name == "ChargeLog" }'>
            <router-link :to="{ name: 'ChargeLog' }"><i class="mdi mdi-history"></i><span
                class="nav-text">로그관리</span></router-link>
          </li>

          <li :class='{ active: name == "ManageMainNumber" }'>
            <router-link :to="{ name: 'ManageMainNumber' }"><i class="mdi mdi-help"></i><span
                class="nav-text">번호관리</span></router-link>
          </li>
        </ul>
      </div>
      <!-- #/ nk nav scroll -->
    </div>
  </transition>

  <!-- #/ sidebar -->
</template>

<script>
import request from "@/plugins/http"

import {
  mapActions,
  mapGetters
} from 'vuex'

export default {
  name: 'NavigationPage',
  data() {
    return {
      name: '',
      sendAudit: '',
      applyCorp: '',
      callerAudit: '',
      refund: '',
      inquiry: '',
      businessPartnerAudit: '',
    }
  },

  computed: {
    newSendAudit() {
      return this.getNewArr().includes('sendAudit')

    },
    newApplyCorp() {
      return this.getNewArr().includes('applyCorp')

    },
    newCallerAudit() {
      return this.getNewArr().includes('callerAudit')

    },
    newRefund() {
      return this.getNewArr().includes('refund')

    },
    newInquiry() {
      return this.getNewArr().includes('inquiry')

    },
    newNumber() {
      return this.getNewArr().includes('mainnumberCount')

    },
    newBusinessPartnerAudit() {
      if (!this.businessPartnerAudit) {
        return false
      } else {
        return true
      }
    },
  },
  watch: {
    $route() {
      this.name = this.$route.name
    },
  },

  methods: {
    ...mapGetters(["pageName", "toggleNavStatus", "getNewArr"]),
    ...mapActions(["goMainPage", "toggleNav"]),
    // async getStatic() { // 대시보드 통계 가져옴
    //   try {
    //     const res = await this.$axios.get('/api/mng/account/dashboard')
    //     if (res.data.success) {
    //       this.sendAudit = res.data.sendAudit;
    //       this.applyCorp = res.data.applyCorp;
    //       this.callerAudit = res.data.callerAudit;
    //       this.refund = res.data.refund;
    //       this.inquiry = res.data.inquiry;
    //       this.statisticsInfos = res.data.statisticsInfos;
    //       this.businessPartnerAudit = res.data.businessPartnerAudit;
    //     } else {
    //       //this.hasReport = false
    //     }
    //     //window.scrollTo(0,0)

    //   } catch (err) {
    //     if (!err.response.status == 403) {
    //       alert('오류가 발생했습니다.')
    //       console.error(err)
    //     }

    //   }
    // },
    nav(name) {
      if (name === this.$route.name) {
        this.$router.go() //NavigationDuplicated 처리(새로고침 한 것 처럼..)
      }
      this.goMainPage({
        pageName: name
      })
      this.navStatus(name)
    },
    navStatus(name) {
      this.navList.map(function (o) {
        o.status = false
        o.title === name ? o.status = true : false
      })
    },
    checkAndSendInstantMessage() {
      alert('보내기')
    },
    async logOn() {
      const result = await request('/monitor/sql-trace-on', 'get')
      console.log(result)
    },
    async logOff() {
      const result = await request('/monitor/sql-trace-off', 'get')
      console.log(result)
    },

  },

  mounted() {
    this.name = this.$route.name
    // this.getStatic();

  }
}

</script>
<style scoped>
.mobile_hamburger,
.mobile_exit {
  display: none;
}

@media (max-width: 767px) {

  .mobile_exit {
    display: unset;
    width: 10px;
    right: 10px;

    position: absolute;
    /* content: url('/images/partner_btn_close.svg'); */
  }

  .v-enter-active {
    transition: all 0.3s ease-out;
  }

  .v-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .v-enter-from,
  .v-leave-to {
    transform: translateX(-20px);
    opacity: 0;
  }

}
</style>
