<template>
  <!-- footer -->
  <div class="footer">
    <div class="copyright">
      <p>Copyright &copy; <a href="#">livrio Corp.</a> 2022</p>
    </div>
  </div>
  <!-- #/ footer -->
</template>

<script>
export default {
  name: 'FooterPage'
}
</script>

<style scoped>

</style>
