<template>
  <div id="wrapper">
    <div-header></div-header>
    <div-navi></div-navi>
    <!-- content body -->
    <div class="content-body">
        <router-view></router-view>
      <!-- #/ container -->
    </div>
    <!-- #/ content body -->
    <div-footer></div-footer>
    <!-- <loading
      :active.sync="loadingbar"
      :can-cancel="true"
      is-full-page
      :width="80"
      :height="80"
      :color="'#4365ED'"
      :background-colr="'#ebd4f2'"
    /> -->
  </div>
</template>

<script>
import DivHeader from './Header.vue'
import DivNavi from './Navigation.vue'
import DivFooter from './Footer.vue'
// import Loading from "vue-loading-overlay";

// import "vue-loading-overlay/dist/vue-loading.css";

// import { mapGetters } from "vuex";

export default {
  name: 'LayoutPage',
  components: {
    DivHeader,
    DivNavi,
    DivFooter,
    // Loading
  },
  computed:{
    //  ...mapGetters(["loadingbar"]),
  }
}
</script>

<style scoped>
</style>
