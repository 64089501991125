export default class ModalHandler {
  page = {
    index: ""
  };

  constructor () {
    //
  }

  isActive (index) {
    return index === this.page.index;
  }

  open (index) {
    this.page.index = index;
  }

  close () {
    this.page.index = "~";
  }
}
